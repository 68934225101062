<template>
    <div class="aftersalesdetail-page">
        <div class="aftersalesinfo-body">
            <div class="list-item">
                <p>发货单号：</p>
                <p class="p1">{{ detailData.order ? detailData.order.order_sn : "" }}</p>
            </div>
            <div class="list-item">
                <p>申请时间：</p>
                <p>{{ detailData.created_at }}</p>
            </div>
            <div class="list-item">
                <p>审批状态：</p>
                <p class="p2" v-if="detailData.status === 1">已通过</p>
                <p class="p1" v-if="detailData.status === 2">已驳回</p>
                <p class="p1" v-if="detailData.status === 0">审核中</p>
            </div>
            <div class="inventorylist-item">
                <p>售后清单：</p>
                <p v-for="(item, index) in detailData.poles">{{ item.production }}</p>
            </div>
            <div class="description-item">
                <p>问题描述：</p>
                <p>{{ detailData.remark }}</p>
            </div>
            <div class="image-item">
                <p>图片附件：</p>
                <van-grid :border="false" :column-num="3">
                    <van-grid-item v-for="(item, index) in detailData.images">
                        <van-image :src="item" />
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <div class="reply-body" v-if="detailData.check && detailData.status !== 0">
            <div class="replytime-item">
                <p>审批时间：</p>
                <p>{{ detailData.check.status_at }}</p>
            </div>
            <div class="replyreply-item">
                <p>审批备注：</p>
                <p>{{ detailData.check.remark }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { GET_GOODS_AFTER_SALE_DETAIL } from "@/api/goods";
import usePublic from "@/hooks/usePublic";
import { onMounted, ref } from "vue";

let { $route } = usePublic();

onMounted(() => {
    getDetail();
});

// 详情
let detailData = ref({});

let getDetail = () => {
    GET_GOODS_AFTER_SALE_DETAIL({ id: $route.params.id }).then((res) => {
        detailData.value = res.data.data;
        console.log(111, res.data.data);
    });
};
</script>

<style lang="scss" scoped>
.aftersalesdetail-page {
    min-height: 100vh;

    background: #f0f0f0;
    .aftersalesinfo-body {
        min-height: 300px;
        background: #fff;
        padding: 20px 15px;
        .list-item {
            font-size: 14px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            color: #000;
            p:nth-child(1) {
                color: #999;
            }
            .p1 {
                color: #df5f0b;
            }
            .p2 {
                color: #43cf7c;
            }
        }
        .inventorylist-item {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;
            p {
                margin: 10px 0;
                color: #000;
            }
            p:nth-child(1) {
                color: #999;
            }
        }
        .description-item {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            color: #000;
            p:nth-child(1) {
                color: #999;
                margin-bottom: 10px;
            }
        }
        .image-item {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            p:nth-child(1) {
                color: #999;
                margin-bottom: 10px;
            }
        }
    }
    .reply-body {
        margin-top: 20px;
        min-height: 30px;
        padding: 20px 15px;
        background: #fff;
        .replytime-item {
            font-size: 14px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            p:nth-child(1) {
                color: #999;
            }
        }
        .replyreply-item {
            font-size: 14px;
            p:nth-child(1) {
                color: #999;
                padding-bottom: 10px;
            }
        }
    }
}
</style>
